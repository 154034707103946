import { Navbar, Nav, Button, Container, ButtonGroup, ToggleButton} from "react-bootstrap"
import { connect } from "react-redux";
import { Link, useResolvedPath, useMatch, useLocation } from  "react-router-dom";
import logo from '../../images/logo.png';
import { shortAddress } from '../utils';
import {toggleTheme} from '../redux/actions';
import {providers} from "web3modal";
import FrontierIcon from '../../images/frontier-wallet.svg';

function NavBar(props) {
    const tabs = [
        {"title": "Lend to Earn", "to": "/"},
        {"title": "Investment Vaults", "to": "borrow"},
        {"title": "Loan Portfolio", "to": "portfolio"},
        {"title": "Rewards", "to": "rewards"},
        // {"title": "Leaderboard", "to": "leaderboard"}
    ]

    const getIconColor = value => {
        if(props.themeMode === "dark" && value === "dark") {
            return "text-primary"
        } else if ( props.themeMode === "light" && value === "light") {
            return "text-warning"
        } else {
            return ""
        }
    }

    const themeSwitch = (<ButtonGroup>
        {[
            {"value": "dark", "icon": "fa fa-moon"},
            {"value": "light", "icon": "fa fa-lightbulb"}
        ].map((radio, idx) => (
        <ToggleButton
            title={`Switch to ${radio.value} theme`}
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={props.themeMode === radio.value ? props.themeMode : "primary"}
            name="radio"
            value={radio.value}
            checked = {radio.value === props.themeMode}
            onChange={(e) => props.toggleTheme(e.currentTarget.value)}
        >
            <i className={`${radio.icon} ${getIconColor(radio.value)}`}/>
        </ToggleButton>
        ))}
    </ButtonGroup>);

    const connectButton = props.connected  ? <div>
        <div><span className="user-address"><i className="fa fa-user me-2"/>{shortAddress(props.address)}</span></div>
        <div className="mt-1 text-center"><small style={{textDecoration: "underline"}} className="cursor-pointer" onClick={()=> props.onDisconnect()}>Disconnect</small></div>
    </div> : <Button  variant={props.themeMode === "light" ? props.themeMode : "primary"} onClick={()=> props.onConnect()}>
        <img height="14" className="me-1"  alt="metamask" src={providers.METAMASK.logo}/>
        <img height="14" className="me-1"  alt="walletconnect" src={providers.WALLETCONNECT.logo}/>
        <img height="14" className="me-1"  alt="coinbase" src={providers.COINBASEWALLET.logo}/>
        <img height="14" className="me-2"  alt="frontier" src={FrontierIcon}/>
        Connect
    </Button>

    return (
        <div>
            <Navbar collapseOnSelect={true} bg={props.themeMode === "dark" ? "black": "white"} className={props.themeMode === "dark" ? "" : "border-bottom"} expand="lg">
                <Container>
                    <Navbar.Brand>
                        <img height="34" src={logo} alt="logo" />
                    </Navbar.Brand>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto me-auto">
                            {tabs.map((tab, i)=> {
                                return <NavItem tab={tab} key={i}/>
                            })}
                        </Nav>
                    </Navbar.Collapse>
                    <div className="d-flex align-items-center">
                        <div className="d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex me-4">{themeSwitch}</div>
                        {connectButton}
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </Container>
            </Navbar>
            <div className="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none text-end mb-4 me-2">
                <div className='wallet-balances me-2 inline'>
                    <a href='https://medium.com/@paxofinance/how-to-guide-paxo-mainnet-alpha-9ec7303fa4ae' target='_blank' rel='noreferrer' className='text-light-1'><small>Help <i className="fas fa-external-link-alt"></i></small></a>
                </div>
                {themeSwitch}
            </div>     
        </div>

    )
}

function NavItem(props) {
    let tab = props.tab;
    let resolved = useResolvedPath(tab.to);
    // let match = useMatch({ path: resolved.pathname, end: true });
    let match = resolved.pathname===useLocation().pathname;
    return (
        <Nav.Link active={match} as={Link} to={tab.to} href="#">{tab.title}</Nav.Link>
    )
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        address: state.get("accAddress"),
        chainId: state.get("chainId"),
        networkId: state.get("networkId"),
        themeMode: state.get("themeMode")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleTheme: mode => dispatch(toggleTheme(mode))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)