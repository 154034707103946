import { fromJS } from 'immutable';
import moment from 'moment';
import { useState } from 'react';
import {Modal, Row, Col, Badge, Table} from 'react-bootstrap';
import { getTokenIcon, shortAddress } from '../../utils';

export default function DetailsModal(props) {
    const [activeTab, setActiveTab] = useState("Opened/Closed");
    const row = props.row;
    const getAllEvents = () => {
        let data = fromJS([]);
        const positions = Object.keys(row.get("positions").toJS());
        const renderTabs = activeTab === "Paid" ? ["paid"] : ["opened", "closed"];
        console.log("tabs", renderTabs)
        positions.forEach((market)=> {
            renderTabs.forEach((key)=> {
                row.getIn(["positions", market, key, "data"]).forEach((item)=> {
                    item = item.set("action", key);
                    data = data.push(item);
                });
            } );
        })
        return data.sort((a, b)=> Number(a.get("timestamp")) - Number(b.get("timestamp")));
    }

    const getCurrentAssetPrices = ()=> {
        let data = fromJS({});
        props.currentPrices.keySeq().toArray().forEach((address)=> {
            const asset = props.assets.find((item)=> item.get("address") === address);
            if (!data.has(asset.get("symbol"))) {
                data = data.set(asset.get("symbol"), props.currentPrices.get(address));
            }
        });
        return data;
    }
    const currentPrices = getCurrentAssetPrices();
    return(
        <Modal
            size="lg"
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header closeButton className="text-light-2">
                {shortAddress(row.get("address"))}
            </Modal.Header>
            <Modal.Body>
            <Row className="text-light-1"> 
                <Col>
                    <div className={row.get("pnl") >= 0 ? "text-success": "text-danger"}>${row.get("pnl").toFixed(2)}</div>
                    <div className='title small text-light-2'>PnL</div>
                </Col>
                <Col>
                    <div className={row.get("roi") >= 0 ? "text-success": "text-danger"}>{row.get("roi").toFixed(2)}%</div>
                    <div className='title small text-light-2'>ROI</div>
                </Col>
                <Col>
                    ${row.get("balance").toFixed(2)}
                    <div className='title small text-light-2'>Current Balance</div>
                </Col>
                <Col>
                    ${row.get("closed").toFixed(2)}
                    <div className='title small text-light-2'>Total Closed</div>
                </Col>
                <Col>
                    ${row.get("paid").toFixed(2)}
                    <div className='title small text-light-2'>Total Paid</div>
                </Col>
                
               
            </Row>
            <hr/>

            <ul className="nav nav-pills  mb-3">
                {["Opened/Closed", "Paid"].map((t, i)=> <li 
                    key={i}
                    className={`nav-item cursor-pointer`}
                    onClick={()=> setActiveTab(t)}
                >
                   <a className={`nav-link ${t===activeTab ? props.themeMode === "dark" ? "bg-dark text-light-1" : "active": ""}`} href="#"> {t}</a>
                </li>)}
            </ul>
            <Table bordered hover variant={props.themeMode} className='text-light-1 small'>
                <thead>
                    <tr>
                        <th>Asset</th>
                        <th>USD</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {getAllEvents().map((item, i) => {
                        return <tr key={i}>
                            <td>
                                {item.get(item.get("action") === "opened" ? "amountOut":"amount")} {item.get(item.get("action") === "opened" ? "amountOutSymbol": "symbol")}
                                <Badge className={`float-end bg-${props.themeMode==="dark" ? "dark": "light border text-body"} ms-2`}>{item.get("action")}</Badge>
                            </td>
                            <td>${item.get("priceUsd").toFixed(2)}</td>
                            <td>{moment.unix(item.get("timestamp")).format("ll hh:mm A")}</td>
                        </tr>
                    })}
                </tbody>
               </Table>
               <div className="mt-4 text-light-2">Asset's current prices at the time of leaderboard generation</div>
               <Row className='mt-2'>
                    {currentPrices.keySeq().toArray().map((symbol)=> {
                        return <Col key={symbol}>
                            <div className='text-light-1'>$ {Number(currentPrices.get(symbol)).toFixed(2)}</div>
                            <div className="text-light-2 small">{symbol}</div>
                        </Col>
                    })}
               </Row>
            </Modal.Body>
        </Modal>
    )
}

