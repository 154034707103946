import { useState } from 'react';
import {connect} from 'react-redux';
import {Card, Row, Col, Button, Spinner, Popover, OverlayTrigger, ListGroup, ListGroupItem, Badge} from 'react-bootstrap';

import {getTokenIcon, fixed2Decimals, toPrecisin, getUSDPrice, mulFixed, divFixed, decimal2Fixed, mul, minusFixed, addFixed, division} from '../../../utils';
import LendModal from '../../dashboard/components/LendModal';
import { fromJS } from 'immutable';
import SettleModal from '../../dashboard/components/SettleModalV2';

function BorrowingAssets(props) {
    const [activeWithdrawToken, setActiveWithdrawToken] = useState(null);
    const [activeSettleToken, setActiveSettleToken] = useState(null);
    const borrowAssets = props.assets.filter((item)=> item.get("borrowEnabled"));
    let boughtAssets = [];
    props.assets.filter((item)=> item.get("buyEnabled") === true).map((asset)=> {
        if (props.accountSnapshot.hasIn([asset.get("address"), "lendBalance"]) && Number(props.accountSnapshot.getIn([asset.get("address"), "lendBalance"])) > 0) {
            boughtAssets.push(asset);
        }
        // boughtAssets.push(asset);
    })

    const getAPY = (address) => {
        let apy = props.accountSnapshot.getIn([address, "borrowAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }

    const getLendAPY = address => {
        let apy = props.accountSnapshot.getIn([address, "lendAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }
    const {data, overallCurrent, borrowData} = props;

    const getTotalValue = () => {
        let totalValue = 0;
        let assetsShortValues = fromJS({});
        boughtAssets.forEach((asset)=> {
            if (props.accountSnapshot.hasIn([asset.get("address"), "lendBalance"]) && Number(props.accountSnapshot.getIn([asset.get("address"), "lendBalance"])) > 0) {
                let assetAddress = asset.get("address").toLowerCase();
                if (asset.get("isStable") === true) {
                    let shortValue = 0;
                    let assetPerBorrowMaket = fromJS({});
                    console.log(asset.get("symbol"), ">>>>>>>")
                    //handle short
                    const assetBalance = fixed2Decimals(props.accountSnapshot.getIn([asset.get("address"), "lendBalance"]), asset.get("decimals"));
                    let borrowMarkets = Object.keys(data.getIn([assetAddress, "current", "borrow"]).toJS());
                    borrowMarkets.forEach((address)=> {
                        assetPerBorrowMaket = assetPerBorrowMaket.set(address, fromJS({"amount": 0 , "per": 0}));
                    })
                    let totalBought = 0;
                    const borrowItems = data.getIn([assetAddress, "current", "borrowHistory"])
                    borrowItems.forEach((item)=> {
                        totalBought = addFixed(totalBought , item.get("boughtAssetAmount"));
                        assetPerBorrowMaket = assetPerBorrowMaket.setIn([item.get("borrowAsset"), "amount"], addFixed(
                            assetPerBorrowMaket.getIn([item.get("borrowAsset"), "amount"]),
                            item.get("boughtAssetAmount")
                        ));
                    });
                    borrowMarkets.forEach((address)=> {
                        let assetPer = division(assetPerBorrowMaket.getIn([address, "amount"]), totalBought);
                        console.log("assetPer", address, assetPer);
                        assetPerBorrowMaket = assetPerBorrowMaket.setIn([address, "per"], Number(assetPer));
                        let balance = Number(assetBalance) * Number(assetPer); 
                        let price = props.accountSnapshot.getIn([address, "oraclePrice"]);
                        let target = balance /price;

                        shortValue = shortValue + (target * price);
                    });
                    totalValue = totalValue + shortValue;
                    assetsShortValues = assetsShortValues.set(asset.get("address"), shortValue);

                } else {
                    totalValue = totalValue + Number(getUSDPrice(
                        props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
                        props.accountSnapshot.getIn([asset.get("address"), "lendBalance"]),
                        asset.get("decimals")
                    ))
                }
            }
         });
         return {totalValue, assetsShortValues};
    }


    const getBorrowBalances = () => {
        let totalInterest = 0;
        let assetsBorrowBalance = fromJS({});
        borrowAssets.forEach((asset) => {
            const currentBorrowBalance = borrowData.getIn([asset.get("address").toLowerCase(), "current", "balance"]);
            const outStanding = props.accountSnapshot.getIn([asset.get("address"), "borrowBalance"]);

            assetsBorrowBalance = assetsBorrowBalance.set(asset.get("address").toLowerCase(), fromJS({
                "symbol": asset.get("symbol"),
                "decimals": asset.get("decimals"),
                "amount": outStanding
            }));
            // totalBorrowBalance = totalBorrowBalance + Number(getUSDPrice(
            //     props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
            //     props.accountSnapshot.getIn([asset.get("address"), "borrowBalance"]),
            //     asset.get("decimals")
            // ));
            totalInterest = totalInterest + Number(getUSDPrice(
                props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
                minusFixed(outStanding, currentBorrowBalance),
                asset.get("decimals")
            ))
        });
        return {totalInterest, assetsBorrowBalance}
    }
    const {totalInterest, assetsBorrowBalance} = getBorrowBalances();

    // const totalInterest = totalBorrowBalance > 0 ? totalBorrowBalance - (overallCurrent.get("totalBorrow") - overallCurrent.get("totalRepaid")) : 0;
    const {totalValue, assetsShortValues} = getTotalValue();
   
    const overallProfit = totalValue - (
        overallCurrent.get("totalBorrow")+
        overallCurrent.get("totalDownpayment")+
        totalInterest
    );
    return (
       <div>
            {props.isLoading && <div className="text-center mt-2"><Spinner size="sm" variant="secondary" animation="grow"/></div>}
            {boughtAssets.length > 0 && <div>
            <Card body className="mt-4">
                    <div className="text-light-1">
                        <Badge bg={props.theme==="dark"? "black text-light-1" : "beige text-body border"}>Investment Portfolio</Badge>
                        <span className='mx-4'>|</span>
                        <span>Current Value</span>
                        <span className="ms-2 text-success">$ {totalValue.toFixed(2)}</span>
                        <span className='mx-4'>|</span>
                        <span className="">Unrealized PnL</span>
                        <span className="ms-2">
                            <span className={`text-${overallProfit >= 0 ? "success": "danger"}`}>{overallProfit >=0 ? "$": "- $"}&nbsp;{Math.abs(overallProfit).toFixed(2)}</span>
                            <OverlayTrigger
                                trigger="click"
                                key="profit-overall"
                                placement="right"
                                overlay={
                                    <Popover id={`profit-overall-popover`}>
                                    <Popover.Header as="h3">Profit</Popover.Header>
                                    <Popover.Body className='small'>
                                        Current Value: $ {totalValue.toFixed(2)}<br/>
                                        Borrowed: $ {overallCurrent.get("totalBorrow").toFixed(2)}<br/>
                                        Downpayment: $ {overallCurrent.get("totalDownpayment").toFixed(2)}<br/>
                                        Interest Payable: $ {totalInterest.toFixed(2)}
                                        <hr/>
                                        Profit: <span className={`text-${overallProfit >= 0 ? "success": "danger"}`}>{overallProfit >=0 ? "$": "- $"}&nbsp;{Math.abs(overallProfit).toFixed(2)}</span>
                                    </Popover.Body>
                                    </Popover>
                                }
                            >
                                <i className='ms-2 cursor-pointer small fas fa-info-circle'/>
                            </OverlayTrigger>
                        </span>
                    </div>
                <div className="token-list">
                    {boughtAssets.map((asset, j) => <Asset 
                        key={j}
                        asset={asset}
                        currentData={data.getIn([asset.get("address").toLowerCase(), "current"])}
                        accountSnapshot={props.accountSnapshot}
                        assetsBorrowBalance={assetsBorrowBalance}
                        totalInterest={totalInterest}
                        assetsShortValues={assetsShortValues}
                        theme={props.theme}
                        setActiveWithdrawToken= {()=> setActiveWithdrawToken(asset)}
                        setActiveSettleToken={()=> setActiveSettleToken(asset)}
                    />)}
                </div>
            </Card>

            {activeWithdrawToken !==null && <LendModal
                activeTab="Withdraw"
                show={activeWithdrawToken !== null}
                token={activeWithdrawToken}
                lendAPY={getLendAPY(activeWithdrawToken.get("address"))}
                onHide={()=> setActiveWithdrawToken(null)}
            />}


            {activeSettleToken !==null && <SettleModal
                show={activeSettleToken !== null}
                token={activeSettleToken}
                // borrowAssets={borrowAssets.filter((item)=> item.get("address") !== activeSettleToken.get("address"))}
                borrowAssets={props.assets.filter((item) => activeSettleToken.get("borrowAssets").includes(item.get("address")))}
                onHide={()=> setActiveSettleToken(null)}
            />}
        </div>}
        </div>
    )
}


function Asset({
    asset, currentData, accountSnapshot, totalInterest, theme,
    setActiveWithdrawToken, setActiveSettleToken, assetsShortValues
}) {
    if(currentData.get("balanceTokens") <=0) {
        return null;
    }
    const assetInterest = Number(mul(totalInterest, currentData.get("borrowPer")));
    // const outStanding = currentData.get("totalBorrow") + assetInterest + - currentData.get("totalRepaid");

    const exchangeRate = accountSnapshot.getIn([asset.get("address"), "exchangeRateStored"]);
    const balanceCurrent = divFixed(mulFixed(currentData.get("balanceTokens"), exchangeRate), decimal2Fixed(1, 18));

    const totalPrice =  asset.get("isStable") === true ? assetsShortValues.get(asset.get("address"))  : getUSDPrice(
        accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
        // accountSnapshot.getIn([asset.get("address"), "lendBalance"]),
        balanceCurrent,
        asset.get('decimals'));
  
    const borrowedPrice =  Number(currentData.get("totalBorrow")) + Number(currentData.get("totalDownpayment"));
    const profit = Number(totalPrice) - Number(borrowedPrice) - assetInterest;
    
    return <Row className="token-list-item borrowing" style={{position: "relative"}}>
        <Col sm={6} xs={6} md={1}>
            <div className="title">{asset.get("symbol")}</div>
            <div className='value'>
                <img height="12" src={getTokenIcon(asset.get("symbol"))} alt="token"/>
                {fixed2Decimals(balanceCurrent, asset.get("decimals"), 2)}
            </div>
        </Col>
        <Col sm={6} xs={6} md={2}>
            <div className="text-success">
                <div className="title">Current Value</div>
                <div className="value text-success">
                    $ {totalPrice}
                </div>
            </div>
        </Col>
        <Col sm={6} xs={6} md={2}>  
            <div className="title">Downpayment</div>
            <div className="value"> 
                <Item
                    title="Downpayment"
                    items={currentData.get("downpayment")}
                />
            </div>
        </Col>
        <Col sm={6} xs={6} md={2}>
            <div className="title">Avg Cost</div>
            <div className="value">
                <Item items={currentData.get("avgBuyingPrices")} />
            </div>
        </Col>
        <Col sm={6} xs={6} md={2}>
            <div className="title">Unrealized PnL</div>
            <div className="value">
                <span className={`text-${profit >= 0 ? "success": "danger"}`}>{profit >=0 ? "$": "- $"}&nbsp;{Math.abs(profit).toFixed(2)}</span>
                {/* <OverlayTrigger
                    trigger="click"
                    key={`profit-${asset.get("address")}`}
                    placement="right"
                    overlay={
                        <Popover id={`profit-${asset.get("address")}-popover`}>
                        <Popover.Header as="h3">Profit</Popover.Header>
                        <Popover.Body className='small'>
                            Current Value: $ {totalPrice}<br/>
                            Borrowed Price: $ {borrowedPrice.toFixed(2)}<br/>
                            BorrowPer: {Number(currentData.get("borrowPer")).toFixed(2)}<br/>
                            Interest Payable: $ {assetInterest.toFixed(2)}
                            <hr/>
                            Profit: <span className={`text-${profit >= 0 ? "success": "danger"}`}>{profit >=0 ? "$": "- $"}&nbsp;{Math.abs(profit).toFixed(2)}</span>
                        </Popover.Body>
                        </Popover>
                    }
                >
                    <i className='ms-2 cursor-pointer  fas fa-info-circle'/>
                </OverlayTrigger> */}
            </div>
        </Col>
        <Col sm={6} xs={6} md={1}>
            <div className="title">Withdrawals</div>
            <div className="value">
                <img src={getTokenIcon(asset.get("symbol"))} alt="token"/>
                {fixed2Decimals(currentData.get("redeemed"), asset.get("decimals"), 2)}
            </div>
        </Col>
        <Col sm={6} xs={6} md={1}>
            <div className='d-grid'>
                <Button variant="primary" size="sm" onClick={()=> setActiveWithdrawToken()}>Withdraw</Button>
            </div>
        </Col>
        <Col sm={6} xs={6} md={1}>
            <div className='d-grid'>
                <Button variant="primary" size="sm" onClick={()=> setActiveSettleToken()}>Sell</Button>
            </div>
        </Col>
    </Row>
}

export function Item({value, items, isPrice=false, title}) {
    const keys = items.keySeq().toArray();
    const filtered = keys.filter((key, i) => items.getIn([key, "amount"]) > 0);
    const getPopover = () => {
        return (
            <Popover id={Math.random()}>
                {/* {title && <Popover.Header as="h3">{title}</Popover.Header>} */}
                <Popover.Body>
                    <ListGroup variant='flush'>
                        {filtered.map((key, i)=> <ListGroupItem key={i}>
                            <img className='me-2' src={getTokenIcon(items.getIn([key, "symbol"]))} height="16" alt="token"/> {fixed2Decimals(items.getIn([key, "amount"]), items.getIn([key,"decimals"]))} {items.getIn([key, "symbol"])}
                        </ListGroupItem>)}
                    </ListGroup>
                </Popover.Body>
            </Popover>
        )
    }
    if (value === undefined) {
        if (filtered.length > 0) {
            let item = items.get(filtered[0]);
            value = `${fixed2Decimals(item.get("amount"), item.get("decimals"), 2)} ${item.get("symbol")}`;
            value = <span>
                <img src={getTokenIcon(item.get("symbol"))} height="14"  alt="token"/>&nbsp;
                {fixed2Decimals(item.get("amount"), item.get("decimals"), 2)}</span>
        }
    }
    if (!value) {
        return "--";
    }
    return (
        <span>
             {isPrice && "$"}{value}
            { (isPrice ? filtered.length > 0 : filtered.length > 1) && <OverlayTrigger trigger="click" placement="bottom" overlay={getPopover()}>
                {isPrice ? <span className='ms-1 cursor-pointer'>
                    <i style={{ fontSize: "12px"}} className='fas fa-circle-chevron-down'/>
                </span> :<span className='ms-1 cursor-pointer' style={{
                    display: "inline-block",
                    lineHeight: "11px",
                    fontSize: "10px",
                    // border: "1px solid #8C7DF7",
                    // color: "#8C7DF7 !important",
                    // backgroundColor: "#212529",
                    borderRadius: "5px",
                    padding: "1px 2px"
                }}>
                    +{filtered.length - 1}
                    <i className='fas fa-circle-chevron-down ms-1'/>
                </span>}
            </OverlayTrigger>}
        </span>
    )
}

const mapStateToProps = state => {
    return {
        theme: state.get("themeMode"),
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
        transactions: state.getIn(["transactions", "data"]),
        isLoading: state.getIn(["transactions", "isLoading"])
    }
}

export default connect(mapStateToProps)(BorrowingAssets);