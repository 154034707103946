import { connect } from "react-redux";
import {Modal, Button, Spinner, Badge, ListGroup, ListGroupItem} from 'react-bootstrap';
import { useEffect } from "react";
import { useState } from "react";
import { fetchGasPrices, saveGasPreference, setActivePoolFee } from "../../redux/actions";
import uniswapImage from '../../../images/uniswap.svg';

function SettingsModal(props) {
    const [activeGas, setActiveGas] = useState(props.active);
    const [activeFee, setActiveFee] = useState(props.activePoolFee);

    useEffect(()=> {
      props.fetchGasPrices();
      const interval = setInterval(()=>  props.fetchGasPrices(), 3000);
      return () => clearInterval(interval);
    }, [])

    const items =  [
        {"key": "safeLow", "label": "Safe Low"},
        {"key": "standard", "label": "Standard"},
        {"key": "fast", "label": "Fast"},
        {"key": "fastest", "label": "Fastest"},
    ]
    
    return  <Modal
        fullscreen
        show={props.show}
        onHide={()=> props.onHide()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        // contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        contentClassName={props.themeMode === "dark" ? "semi-black-backdrop": "semi-black-backdrop"}
        // dialogClassName="semi-black-backdrop"
    >
        <div className='d-flex align-items-center justify-content-center' style={{height: "100%"}}>
            <div className={props.themeMode === "dark" ? "bg-black-modal": "bg-black-modal"} style={{minWidth: "400px", maxWidth:"400px", padding: "30px", borderRadius: "28px"}}>
                <ListGroup className="mb-4">
                    <ListGroupItem className="gas-col text-light-1">
                        <i className="fa fa-gas-pump me-2"></i>
                        Gas Price
                    </ListGroupItem>
                    {items.map((item, i) => {
                        return <ListGroupItem className={`gas-col align-items-center cursor-pointer ${activeGas=== item.key ? "active": ""}`} onClick={()=> setActiveGas(item.key)} key={i}>
                            <i className={`me-2 text-secondary ${activeGas=== item.key ? "fas fa-check-circle" : "far fa-circle"}`}/>
                            <span className='small text-light-2'>
                                {item.label}
                                {props.active === item.key && <Badge className="ms-2">Active</Badge>}
                            </span>
                            {props.isLoading ? <Spinner className="float-end" variant="secondary" animation="grow" size="sm" /> : <span className="text-light-1 float-end">
                                {props.gasData.get(item.key)} Gwei
                            </span>}
                        </ListGroupItem>
                    })}
                </ListGroup>
                <ListGroup className="mb-4">
                    <ListGroupItem className="gas-col text-light-1">
                        <img className="me-2 float-left" alt="uniswap" src={uniswapImage} height="18"/>
                        Uniswap Pool Fee Tier
                    </ListGroupItem>
                    {["100", "500", "3000", "10000"].map((fee, i)=> {
                        return <ListGroupItem className={`gas-col align-items-center cursor-pointer ${activeFee=== fee ? "active": ""}`} onClick={()=> setActiveFee(fee)} key={i}>
                            <i className={`me-2 text-secondary ${activeFee=== fee ? "fas fa-check-circle" : "far fa-circle"}`}/>
                            <span className='small text-light-2'>
                                {Number(fee)/10000}&nbsp;%
                            </span>
                            <span className="text-light-1 ms-2">
                                {props.activePoolFee === fee && <Badge className="ms-2">Active</Badge>}
                            </span>
                        </ListGroupItem>
                    })}
                </ListGroup>
                <div className="text-end mt-4">
                    {(props.active !== activeGas || props.activePoolFee !== activeFee) && <Button className="me-2" onClick={()=> {
                        if(props.active !== activeGas) {
                            props.saveGasPreference(activeGas);
                        }
                        if (props.activePoolFee !== activeFee) {
                            props.setActivePoolFee(activeFee);
                        }
                    }}>Save</Button>}
                    <Button variant="outline-secondary" onClick={()=> props.onHide()}>Close</Button>
                </div>
            </div>
        </div>
    </Modal>
}

const mapStateToProps= state => {
    return {
        themeMode: state.get("themeMode"),
        active: state.getIn(["gasPrices", "active"]),
        gasData: state.getIn(["gasPrices", "data"]),
        isLoading: state.getIn(["gasPrices", "isLoading"]),
        activePoolFee: state.get("activePoolFee")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveGasPreference: pref => dispatch(saveGasPreference(pref)),
        fetchGasPrices: () => dispatch(fetchGasPrices()),
        setActivePoolFee: fee => dispatch(setActivePoolFee(fee))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal)