import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {connect} from 'react-redux';
import { getUSDPrice } from '../../../utils';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { getContract } from '../../../utils';
import { comptrollerABI } from '../../../utils/abi';
import { fetchAssetsIn } from '../../../redux/actions';

function Snapshot(props) {
    const contract = getContract(props.web3, comptrollerABI, props.compAddress);

    useEffect (()=> {
        props.fetchAssetsIn(contract, props.accAddress);
    }, [props.accAddress]);

    const assetsIn = props.assestsIn.has(props.accAddress) ? props.assestsIn.get(props.accAddress).map((item) => item.toLowerCase()) : [];
    let borrowAssets = props.assets.filter((item)=> item.get("borrowEnabled"));
    const getTotalValue = () => {
        let value = 0;
        assetsIn && props.assets.filter((item) => assetsIn.includes(item.get("address"))).forEach((token)=> {
            value = value + Number(getUSDPrice(
                props.accountSnapshot.getIn([token.get("address"), "oraclePrice"]),
                props.accountSnapshot.getIn([token.get("address"), "lendBalance"]),
                token.get("decimals"))
            );
        });
        return value.toFixed(2);
    }

    const getTotalBorrowValue = () => {
        let value = 0;
        borrowAssets.forEach((token)=> {
            value = value + Number(getUSDPrice(
                props.accountSnapshot.getIn([token.get("address"), "oraclePrice"]),
                props.accountSnapshot.getIn([token.get("address"), "borrowBalance"]),
                token.get("decimals"))
            );
        });
        return value.toFixed(2);
    }
   
    const totalValue = getTotalValue();
    const totalBorrowValue = getTotalBorrowValue();

    const liquidationPoint = (Number(totalBorrowValue) / 0.9);

    const labels = {
        [totalValue]: '$'+totalValue,
        [liquidationPoint]: 'Liquidation Point: $'+liquidationPoint.toFixed(2),
    }

    return <Card body className=''>
        <Row>
            <Col xs={12} sm={12} md={3}>
                <div className="fs-6 text-light-1">Current Value</div>
                <div className="text-success fs-4 fw-bold">$ {totalValue}</div>
               
            </Col>
            {Number(totalBorrowValue) > 0 && <Col xs={12} sm={12} md={6}>
                <Slider
                    min={0}
                    max={Number(totalValue)}
                    value={Number(liquidationPoint)}
                    // orientation='vertical'
                    labels={labels}
                    // handleLabel={vertical}
                    //   format={(a)=> }
                    disabled={true}
                    tooltip={false}

                    // onChange={this.handleChangeVertical}
                />
            </Col>}
            <Col xs={12} sm={12} md={3} className="text-md-end">
                <div className="fs-6 text-light-1">Borrowed</div>
                <div className="text-danger fs-4 fw-bold">$ {totalBorrowValue}</div>
            </Col>
        </Row>
    </Card>
            
}   

const mapStateToProps = state => {
    return {
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
        compAddress: state.getIn(["contracts", "comptroller"]),
        assestsIn: state.get("assetsIn"),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAssetsIn: (contract, address) => dispatch(fetchAssetsIn(contract, address)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snapshot);