// present day impl
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Alert, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { fixed2Decimals, getTokenIcon, toPrecisin, decimal2Fixed, getContract, divFixed, minusFixed, decodeDexData } from '../../../../utils';
import { borrowAndBuy, fetchEstimate, fetchTotalCash } from '../../../../redux/actions';
import { libABI, pTokenABI } from '../../../../utils/abi';
import { fromJS } from 'immutable';
import { Spinner } from 'react-bootstrap';
import ApprovalModal from '../ApprovalModal';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import DexQuotes from '../DexQuotes';
import ConnectWallet from '../../../../components/ConnectWallet';

function Borrow(props) {
    const [showApprove, setShowApprove] = useState(false);
    const [selectedBuyFactor, setSelectedBuyFactor] = useState(1.1);
    const [targetValue, setTargetValue] = useState("");
    const [estimate, setEstimate] = useState(null);
    const [toggle, setToggle] = useState(false)

    useEffect(()=> {
        fetchCash(props.borrowToken);
    }, [props.borrowToken]);

    const fetchCash = (borrowToken)=> {
        props.fetchTotalCash(getContract(props.web3, pTokenABI, borrowToken.get("address")), borrowToken.get("address"))
    }
   
    const borrowToken = props.borrowToken;
    const targetToken  = props.targetToken;
    const buyFactor = props.snapshot.getIn([borrowToken.get("address"), "buyFactor"]);
    // const buyFactor =5;

    
    let walletBalance = 0;
    let walletBalanceDisplay = "0";
    if ( props.uTokenMeta.hasIn([borrowToken.get("uAddress"), "balance"]) ) {
        walletBalance = Number(props.uTokenMeta.getIn([borrowToken.get("uAddress"), "balance"]));
        walletBalanceDisplay = toPrecisin( fixed2Decimals(walletBalance, borrowToken.get("decimals")), 4);
    }

    const onTargetValueChange = value => {
        // props.getEstimate();
        setTargetValue(value);
    }

    const isValidEstimate = estimate !==null && Number(estimate.quote) > 0;

    let partialAmount = 0;
    let borrowAmount = 0;

    // if(isValidEstimate) {
    //     const estimate = Number(props.estimates.getIn([targetValue, "estimate"]));
    //     partialAmount = mulFixed(estimate, 1 / (Number(selectedBuyFactor)+1))
    //     borrowAmount =  estimate - Number(partialAmount);
    // }

    if(isValidEstimate) {
        partialAmount = divFixed(!toggle ? estimate.quote : decimal2Fixed(targetValue, borrowToken.get("decimals")), selectedBuyFactor);
        borrowAmount =  minusFixed(!toggle ? estimate.quote : decimal2Fixed(targetValue, borrowToken.get("decimals")) , partialAmount);
    }
    
    const borrowAndBuy = () => {
        const contract = getContract(props.web3, pTokenABI, borrowToken.get("address"));
        props.borrowAndBuy(
            props.web3,
            contract,
            props.accAddress,
            {
                borrowAmount: borrowAmount,
                partialAmount: partialAmount,
                targetAmount: !toggle ? decimal2Fixed(targetValue, targetToken.get("decimals")) : estimate.quote,
                targetAddress: targetToken.get("address"),
                dexData: decodeDexData(props.web3, estimate.dexId, props.activePoolFee)
            },
            props.gasType
        );
        props.onHide();

    }

    const toggleToken = (value) => {
        setToggle(value)
    }

    const renderEstimateBreakup = ()=> {
        return <div>
            <Row className="mt-5 slider-balance">
                <Col className="text-center border-end border-2">
                    <div className="mb-2"><small className='text-light-1'>PAY</small></div>
                    <div className="breakup-value">{partialAmount === 0 ?  "---" : fixed2Decimals(partialAmount, borrowToken.get("decimals"), 6)}</div>
                    <div className="text-light-1 fw-lighter">
                        <small>
                            <img className="ms-1 me-1" height={12} src={getTokenIcon(borrowToken.get("symbol"))} alt="token"/>
                            {borrowToken.get("symbol")}
                        </small>
                    </div>
                </Col>
                <Col className="text-center">
                    <div className="mb-2"><small className='text-light-1'>BORROW</small></div>
                    <div className='breakup-value'>{borrowAmount === 0 ? "---"  : fixed2Decimals(borrowAmount, borrowToken.get("decimals"), 6)}</div>
                    <div className="text-light-1 fw-lighter">
                        <small>
                            <img className="ms-1 me-1" height={12} src={getTokenIcon(borrowToken.get("symbol"))} alt="token"/>
                            {borrowToken.get("symbol")}
                        </small>
                    </div>
                </Col>
            </Row>
            <div className="mt-2 small text-light-1">Fee : {borrowAmount === 0 ? "---"  : 0} {borrowToken.get("symbol")}</div>
        </div>
    }

    return(
        <div>
            <Row className='align-items-center'>
                <Col>
                    <DropdownButton
                        menuVariant={props.themeMode}
                        variant="primary"
                        title={borrowToken.has("symbol") ? <span>
                            Borrow {borrowToken.get("symbol")} <img height="20" src={getTokenIcon(borrowToken.get("symbol"))} alt="token"/>
                        </span> : ""}
                    >
                        {props.borrowAssets.map((token, i)=> {
                            return (
                                <Dropdown.Item onClick={()=> {
                                    setSelectedBuyFactor(1.1);
                                    setTargetValue("");
                                    props.onBorrowTokenChange(token);
                                    fetchCash(token)
                                }} key={i} active={borrowToken.get("address") === token.get("address")}>
                                    {token.get("symbol")}<img src= {getTokenIcon(token.get("symbol"))} alt="token" height="20" className='ms-2'/>
                                </Dropdown.Item>
                            )
                        })}
                    </DropdownButton>
                </Col>
                <Col className='text-end text-dark fw-bold'>
                    {/* <img height="20" className='me-2' src={getTokenIcon(borrowToken.get("symbol"))} alt={borrowToken.get("symbol")}/> */}
                    <span className='text-light-2 fw-bold me-2'>APY</span>
                    {props.borrowAPY}
                </Col>
            </Row>
            <Row className="align-items-center mt-4 bg-beige py-3 rounded-12">
                <Col>
                    <ButtonGroup size="sm" className="mb-2">
                        <Button
                            className={toggle ? "text-light-1" : ""}
                            variant={toggle ? "outline-dark": "dark"}
                            onClick={()=> {
                                setSelectedBuyFactor(1.1);
                                setTargetValue("");
                                toggleToken(false)
                            }} 
                        >
                            {targetToken.get("symbol")}
                        </Button>
                        <Button
                            className={toggle ? "" : "text-light-1"}
                            onClick={()=> {
                                setSelectedBuyFactor(1.1);
                                setTargetValue("");
                                toggleToken(true)

                            }} 
                            variant={toggle?  "dark": "outline-dark"}
                        >
                            {borrowToken.get("symbol")} 
                        </Button>
                    </ButtonGroup>
                    <input
                        placeholder={
                            !toggle ? `Enter ${targetToken.get("symbol")} amount` :
                                `Enter ${borrowToken.get("symbol")} amount`
                                
                        }
                        onChange={(e)=> onTargetValueChange(e.target.value)}
                        value={targetValue}
                        type="number"
                        className="form-control"
                        aria-label={borrowToken.get("symbol")} aria-describedby="target-input"
                    />
                         {/* <span className="input-group-text fw-bold text-white" id="target-input">
                            <img className="me-2" height={20} src={getTokenIcon(borrowToken.get("symbol"))} alt="token"/>
                            <small>{borrowToken.get("symbol")}</small>
                        </span> */}
                </Col>
                {targetValue && targetValue !=="" && <Col className="text-center" xs={1}>
                    <span className="fs-3 text-light-1 ">≈</span>
                </Col>}
                {targetValue && targetValue !=="" &&<Col xs={5}>
                    <DexQuotes
                        // tokenIn={!toggle ? borrowToken : targetToken}
                        // tokenOut={!toggle ? targetToken : borrowToken}
                        tokenIn={borrowToken}
                        tokenOut={targetToken}
                        amount={targetValue}
                        op={!toggle ? "buy" : "sell"}
                        updateEstimate={(data)=> setEstimate(data)}
                        activeUniV3PoolFee={props.activePoolFee}
                    />
                </Col>}
            </Row>
            
            <div className="mt-2 py-1 mb-5"><small className='text-light-2'>Select borrow limit</small></div>
            {/* <Slider
                disabled={!isValidEstimate}
                styles={sliderStyles}
                axis="x"
                xmin={1}
                xmax={Number(buyFactor)}
                x={selectedBuyFactor}
                onChange={({ x }) => setSelectedBuyFactor(x)}
            /> */}
            {/* {Array(Number(buyFactor)).fill().map((a, i)=> {
                return  <Badge
                onClick={()=> setSelectedBuyFactor(i+1)}
                bg={i+1 === selectedBuyFactor ? "primary": "dark"}
                className="me-2 cursor-pointer px-3 py-1" 
                key={i}
            >{i+1}x</Badge>
            })} */}
            <InputRange
                formatLabel={value => `${value.toFixed(1)}x`}
                maxValue={Number(buyFactor)}
                minValue={1.1}
                step={0.1}
                value={selectedBuyFactor}
                onChange={value => setSelectedBuyFactor(value)}
            />
            {renderEstimateBreakup()}  
            {props.connected ? <div>
                {walletBalance < partialAmount && <Alert variant="secondary" className="mt-4 small fw-bold">
                    <small>
                        Insufficient wallet balance
                        <span className="ms-3 me-3 text-muted">|</span>
                        <i className="fa fa-wallet"/> {walletBalanceDisplay} {borrowToken.get("symbol")}
                    </small>
                </Alert>}
                {Number(borrowAmount) > Number(props.totalCash) && <Alert variant="secondary" className="mt-4 small fw-bold">
                    <small>
                        Not enough liquidity in protocol
                        <span className="ms-3 me-3 text-muted">|</span>
                        Available: {fixed2Decimals(props.totalCash, borrowToken.get("decimals"))} {borrowToken.get("symbol")}
                    </small>
                </Alert>}

                {!showApprove && <div className='d-grid mt-4'>
                    {props.accessStatus !==1 && <div className='mb-2 text-light-1'>
                        <small><i className="fa fa-info-circle me-2"/>You are not whitelisted to use the protocol.</small><br/>
                    </div>}
                    <Alert variant="secondary">
                        Whitelisted Institutionals and Funds can borrow from vaults.<br/> 
                        <a href="https://discord.com/invite/B6U6kectbV" target="_blank" rel="noreferrer">Contact WeFi team to get whitelisted <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                     </Alert>
                    <Button
                        // disabled={(props.accessStatus!==1 || !isValidEstimate || walletBalance < partialAmount)} 
                        disabled={true} // disabled currently, borrow paused 
                        size="lg"
                        onClick={()=> {
                            if(props.accessStatus === 1) {
                                setShowApprove(true)
                            }
                        }}
                    >
                        Pay
                        {partialAmount > 0 && ` ${fixed2Decimals(partialAmount, borrowToken.get("decimals"))} ${borrowToken.get("symbol")}`}
                    </Button>
                </div>}
            </div> : <div className='d-grid mt-2'>
                <ConnectWallet
                    onConnect={()=> props.onConnect()}
                    themeMode={props.themeMode}
                />
            </div>}
            {showApprove && <ApprovalModal
                desc={`Pay  ${fixed2Decimals(partialAmount, borrowToken.get("decimals"))} ${borrowToken.get("symbol")}`}
                show={showApprove}
                amount = {partialAmount}
                token = {borrowToken}
                onSuccess= {()=> borrowAndBuy()}
                onHide= {()=> setShowApprove(false)}
            />}

        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    return {
        connected: state.get("connected"),
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        estimates: state.hasIn(["swapEstimates", props.targetToken.get("uAddress")]) ? state.getIn(["swapEstimates", props.targetToken.get("uAddress")]) : fromJS({}),
        totalCash: state.hasIn(["totalUnderlyingCash", props.borrowToken.get("address")]) ? state.getIn(["totalUnderlyingCash",  props.borrowToken.get("address")]) : 0,
        uniswapQuoter: state.getIn(["contracts", "uniswapQuoter"]),
        libAddress: state.getIn(["contracts", "libABI"]),
        accessStatus: state.getIn(["accessStatus", "status"]),
        gasType: state.getIn(["gasPrices", "active"]),
        activePoolFee: state.get("activePoolFee"),
        themeMode: state.get("themeMode")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchEstimate: (contract, data) => dispatch(fetchEstimate(contract, data)),
        fetchTotalCash: (contract, address) => dispatch(fetchTotalCash(contract, address)),
        borrowAndBuy: (web3, contract, accAddress, data, gasType) => dispatch(borrowAndBuy(web3, contract, accAddress, data, gasType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Borrow);